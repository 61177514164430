import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './pages/HomePage.vue';
import TeamPage from './pages/TeamPage.vue';
import Testimonialse from './pages/Testimonials.vue';
import Blog from './pages/Blog.vue';
import ContactUs from './pages/ContactUs.vue';
import Services from './pages/Services.vue';
import EmailMarketing from './pages/EmailMarketing.vue';
import PayPerClick from './pages/PayPerClick.vue';
import BlogDetails from './pages/BlogDetails.vue';
import UiUxStrategy from './pages/UiUxStrategy.vue';
import GraphicDesign from './pages/GraphicDesign.vue';
import Seo from './pages/Seo.vue';
import DigitalMarketing from './pages/DigitalMarketing.vue';
import WebDevelopment from './pages/WebDevelopment.vue';
import WebDesign from './pages/WebDesign.vue';
import ThankYou from './pages/ThankYou.vue';
import PrivacyPolicy from './pages/PrivacyPolicy.vue';
import TermsConditions from './pages/TermsConditions.vue';
import Unsubscribe from './pages/Unsubscribe.vue';
// import DigitalMarketingnew from './pages/DigitalMarketingnew.vue';


const routes = [
    {
        name: 'HomePage',
        component: HomePage,
        path: ''
    },
    {
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        path: '/privacy-policy',
        meta: {
            title: 'PrivacyPolicy',         
        }
    },
    {
        name: 'Unsubscribe',
        component: Unsubscribe,
        path: '/unsubscribe',
        meta: {
            title: 'Unsubscribe',         
        }
    },
    {
        name: 'TermsConditions',
        component: TermsConditions,
        path: '/terms-conditions',
        meta: {
            title: 'TermsConditions',         
        }
    },
    {
        name: 'TeamPage',
        component: TeamPage,
        path: '/team-page',
        meta: {
            title: 'TeamPage',         
        }
    },
    {
        name: 'Testimonialse',
        component: Testimonialse,
        path: '/testimonials',
        meta: {
            title: 'Testimonialse',         
        }
    },
    {
        name: 'Blog',
        component: Blog,
        path: '/blog',
        meta: {
            title: 'Blog',         
        }
    },
    {
        name: 'ContactUs',
        component: ContactUs,
        path: '/contact-us',
        meta: {
            title: 'Contact-us',         
        }
    },
    {
        name: 'Services',
        component: Services,
        path: '/services',
        meta: {
            title: 'Services',         
        }
    },
    {
        name: 'EmailMarketing',
        component: EmailMarketing,
        path: '/expert-email-marketing-services-in-usa',
        meta: {
            title: 'EmailMarketing',         
        }
    },
    {
        name: 'PayPerClick',
        component: PayPerClick,
        path: '/pay-per-click-services-in-usa',
        meta: {
            title: 'PayPerClick',         
        }
    },
    {
        name: 'BlogDetails',
        component: BlogDetails,
        path: '/blog/:slug',
        meta: {
            title: 'BlogDetails',         
        }
    },
    {
        name: 'UiUxStrategy',
        component: UiUxStrategy,
        path: '/ui-ux-strategy',
        meta: {
            title: 'UiUxStrategy',         
        }
    },
    {
        name: 'GraphicDesign',
        component: GraphicDesign,
        path: '/expert-graphic-design-services-in-usa',
        meta: {
            title: 'GraphicDesign',         
        }
    },
    {
        name: 'Seo',
        component: Seo,
        path: '/expert-seo-services-in-usa',
        meta: {
            title: 'Seo',         
        }
    },
    {
        name: 'DigitalMarketing',
        component: DigitalMarketing,
        path: '/expert-digital-marketing-services-in-usa',
        meta: {
            title: 'DigitalMarketing',         
        }
    },
    // {
    //     name: 'DigitalMarketingnew',
    //     component: DigitalMarketingnew,
    //     path: '/expert-digital-marketing-services-in-usa-new',
    //     meta: {
    //         title: 'DigitalMarketing-new',         
    //     }
    // },
    {
        name: 'WebDevelopment',
        component: WebDevelopment,
        path: '/expert-web-development-services-in-usa',
        meta: {
            title: 'WebDevelopment',         
        }
    },
    {
        name: 'WebDesign',
        component: WebDesign,
        path: '/expert-web-design-services-in-usa',
        meta: {
            title: 'WebDesign',         
        }
    },
    {
        name: 'ThankYou',
        component: ThankYou,
        path: '/thank-you',
        meta: {
            title: 'ThankYou',         
        }
    }
]

// const router = createRouter({
//     mode: "history",
//     history:createWebHistory(process.env.BASE_URL),
//     routes,
//     scrollBehavior() {
//     window.scrollTo(0,0);
//   }
// })

const router = createRouter({
    mode: "history",
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHistory(),
	routes,
    scrollBehavior() {
        document.getElementById('app').scrollIntoView({ behavior: 'smooth' });
    },
});

export default router;