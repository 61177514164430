<template>
      <header id="headerToggle">
        <div class="container">
            <div class="main-header d-flex justify-content-between">

                <button class="navbar-toggler" @click="openNav()">
                  <span class="navbar-toggler-icon"></span>
                </button>


               <div class="logo">
                
                  <router-link to="./">
                    <img src="@/assets/images/logo.png" alt=""/>
                  </router-link>
                  
                
              </div>
              <!--  -->
               <div id="navigation">
                 <nav>
                    <ul>
                        
                          <li>
                            <router-link @click="CloseNav()" to="./"> Home</router-link>
                          </li>
                          
                        <li class="nav-item dropdown">
                          <a @click="onOver_about" class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            About
                          </a>
                          <ul @mouseleave="onLeave_about" class="dropdown-menu" aria-labelledby="navbarDropdown" :class="[about_drop ? 'show' : '']">
                            <!-- <li><router-link to="/team-page"> Our Team </router-link></li> -->
                            <li><router-link @click="CloseNav()" to="/testimonials"> Testimonials </router-link></li>
                          </ul>
                        </li>
                        <li class="nav-item dropdown">
                          <a  @click="onOver"  class="dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                            Services
                          </a>
                          <ul @mouseleave="onLeave" class="dropdown-menu" aria-labelledby="navbarDropdown" :class="{ show }" >
                            <li><router-link @click="CloseNav()" to="/services"> Services Details</router-link></li>
                            <li><router-link @click="CloseNav()" to="/expert-email-marketing-services-in-usa"> Email Marketing </router-link></li>
                            <li><router-link @click="CloseNav()" to="/pay-per-click-services-in-usa"> Pay-Per-Click </router-link></li>
                            <li><router-link @click="CloseNav()" to="/ui-ux-strategy"> UI/UX Strategy </router-link></li>
                            <li><router-link @click="CloseNav()" to="/expert-graphic-design-services-in-usa"> Graphic Design </router-link></li>
                            <li><router-link @click="CloseNav()" to="/expert-seo-services-in-usa"> SEO </router-link></li>
                            <li><router-link @click="CloseNav()" to="/expert-digital-marketing-services-in-usa"> Digital Marketing </router-link></li>
                            <!-- <li><router-link @click="CloseNav()" to="/expert-digital-marketing-services-in-usa-new"> Digital Marketing New</router-link></li> -->
                            <li><router-link @click="CloseNav()" to="/expert-web-development-services-in-usa"> Web Development </router-link></li>
                            <li><router-link @click="CloseNav()" to="/expert-web-design-services-in-usa"> Web Design </router-link></li>
                          </ul>
                        </li>
                        <li><router-link @click="CloseNav()" to="/blog"> Blog</router-link></li>
                        <li><router-link @click="CloseNav()" to="/contact-us">Contact</router-link></li>
                    </ul>
                 </nav>
               </div>
               <div class="gt-btn">
                   <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="primary-hover">Get Started</a>
               </div>
            </div>
        </div>
    </header>

<GlobalModal />




</template>

<script>
import GlobalModal from '@/components/GlobalModal.vue'

export default {
  name: 'PageHeader',
  components: {
    GlobalModal
  },
  props: {
    msg: String
  },
  data() {
    return {
      show: false,
      about_drop: false,
    }
  },
  methods: {
    onOver_about: function () {
      console.log("onover");
      this.about_drop = true;
      this.show = false;
    },
    onLeave_about: function () {
      console.log("onLeave");
      this.about_drop = false;
    },
    onOver: function () {
      console.log("onover");
      this.about_drop = false;
      this.show = true;
    },
    onLeave: function () {
      console.log("onLeave");
      this.show = false;
    },
    openNav: function() {
          let x = document.getElementById('headerToggle');
          if(x.classList.contains('sidebar__header')){
              x.classList.remove('sidebar__header');
          }else{
              x.classList.add('sidebar__header');
          }
    }, 
    CloseNav: function() {
      let x = document.getElementById('headerToggle');
        
      x.classList.remove('sidebar__header');
      this.about_drop = false; 
    }
  }
}
</script>

